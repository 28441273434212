<template>
  <v-dialog v-model="innerValue" transition="slide-y-transition" max-width="680">
    <validation-observer slim ref="observer" v-slot="{ handleSubmit }">
      <v-card>
        <v-card-title class="grey lighten-4">
          <app-id-icon />
          <span class="body-1">
            {{ $t("time.calendar.create_shift") }}
          </span>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="tertiary" icon @click="innerValue = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("buttons.close") }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <validation-provider slim :name="$t('global.employees')" rules="required" v-slot="{ errors }">
                  <employee-picker v-model="selectedEmployees" :items="employees" multiple :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6">
                <date-picker
                  v-model="beginDate"
                  :min="minDate"
                  :max="endDate"
                  :label="$t('global.beginDate')"
                  :clearable="false"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <date-picker
                  v-model="endDate"
                  :min="beginDate"
                  :max="maxDate"
                  :label="$t('global.endDate')"
                  :clearable="false"
                />
              </v-col>
              <template v-if="showOffdayAndBreakday">
                <v-col cols="12" sm="6">
                  <offday-picker v-model="selectedOffdays" :item-disabled="offDayItemDisabled" />
                </v-col>
                <v-col cols="12" sm="6">
                  <breakday-picker v-model="selectedBreakdays" :item-disabled="breakDayItemDisabled" />
                </v-col>
              </template>
              <v-col cols="12">
                <div class="subheading grey--text font-weight-bold">
                  {{ $t("time.calendar.shift_type") }}
                </div>
                <v-radio-group hide-details row v-model="shiftType">
                  <v-radio :value="$enums.SHIFT_TYPES.FIX" :label="$t('shift_types.FIX')" color="primary" />
                  <v-radio :value="$enums.SHIFT_TYPES.FLEXIBLE" :label="$t('shift_types.FLEXIBLE')" color="primary" />
                  <v-radio :value="$enums.SHIFT_TYPES.OFF_DAY" :label="$t('shift_types.OFF_DAY')" color="primary" />
                  <v-radio :value="$enums.SHIFT_TYPES.BREAK" :label="$t('shift_types.BREAK')" color="primary" />
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  slim
                  :name="$t('time.timesheet.shift')"
                  :rules="{ required: shiftRequired }"
                  v-slot="{ errors }"
                >
                  <shift-picker v-model="selectedShifts" :shift-type="shiftType" :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col cols="12" v-if="showCountAsOvertimeCheckbox">
                <v-checkbox
                  v-model="countAsOvertime"
                  color="primary"
                  class="ma-0"
                  hide-details
                  :label="$t('time.calendar.count_as_overtime')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :loading="loading" color="primary" depressed @click="handleSubmit(onSaveBtnClick)">
            {{ $t("buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
  export default {
    name: "ShiftCreateDialog",
    components: {
      EmployeePicker: () => import("./EmployeePicker"),
      ShiftPicker: () => import("./ShiftPicker"),
      OffdayPicker: () => import("./OffdayPicker"),
      BreakdayPicker: () => import("./BreakdayPicker")
    },
    props: {
      value: Boolean,
      employees: Array,
      employee: String,
      date: String,
      holidays: Array
    },
    data: vm => ({
      selectedEmployees: [],
      beginDate: null,
      endDate: null,
      countAsOvertime: false,
      shiftType: vm.$enums.SHIFT_TYPES.FIX,
      selectedShifts: [],
      selectedOffdays: [],
      selectedBreakdays: [],
      loading: false
    }),
    computed: {
      shiftRequired() {
        return this.shiftType === this.$enums.SHIFT_TYPES.FIX || this.shiftType === this.$enums.SHIFT_TYPES.FLEXIBLE;
      },
      showOffdayAndBreakday() {
        return (
          this.dateRange >= 6 &&
          this.shiftType !== this.$enums.SHIFT_TYPES.OFF_DAY &&
          this.shiftType !== this.$enums.SHIFT_TYPES.BREAK
        );
      },
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      },
      isExistHolidayBetweenDates() {
        let result = false;
        if (this.dateRange === 0) {
          result = this.holidays.find(({ date }) => date === this.beginDate);
        } else {
          for (let i = 0; i <= this.dateRange; i++) {
            const currDate = this.$moment(this.beginDate)
              .add(i, "days")
              .format("YYYY-MM-DD");
            if (this.holidays.find(({ date }) => date === currDate)) {
              result = true;
              break;
            }
          }
        }

        return result;
      },
      showCountAsOvertimeCheckbox() {
        return this.dateRange === 0;
      },
      dateRange() {
        return this.$moment(this.endDate).diff(this.beginDate, "days");
      },
      minDate() {
        return this.$moment(this.date)
          .startOf("month")
          .format("YYYY-MM-DD");
      },
      maxDate() {
        return this.$moment(this.date)
          .endOf("month")
          .format("YYYY-MM-DD");
      }
    },
    watch: {
      isExistHolidayBetweenDates(val) {
        if (!val) {
          this.countAsOvertime = false;
        }
      },
      innerValue(val) {
        if (!val) {
          this.resetState();
          this.beginDate = this.endDate = this.date;
          this.selectedEmployees = [this.employee];
        } else if (this.showCountAsOvertimeCheckbox) {
          this.setCountAsOvertime();
        }
      },
      employee(val) {
        if (val) {
          this.selectedEmployees = [val];
        }
      },
      date(val) {
        this.beginDate = this.endDate = val;
        if (this.showCountAsOvertimeCheckbox) {
          this.setCountAsOvertime();
        }
      },
      shiftType(val) {
        if (val === this.$enums.SHIFT_TYPES.OFF_DAY || val === this.$enums.SHIFT_TYPES.BREAK) {
          this.selectedOffdays = [];
          this.selectedBreakdays = [];
        }
      }
    },
    methods: {
      setCountAsOvertime() {
        const holiday = this.holidays.find(({ date }) => this.date === date);
        this.countAsOvertime = holiday ? holiday.countAsOvertime : false;
      },
      offDayItemDisabled(item) {
        return this.selectedBreakdays.includes(item.value);
      },
      breakDayItemDisabled(item) {
        return this.selectedOffdays.includes(item.value);
      },
      onSaveBtnClick() {
        this.loading = true;
        const payload = {
          employees: this.selectedEmployees,
          overtimeDates: []
        };
        for (let i of Array(this.dateRange + 1).keys()) {
          const currentDate = this.$moment(this.beginDate)
            .add(i, "days")
            .format("YYYY-MM-DD");
          const currentDay = this.$moment(currentDate).day();
          const holiday = this.holidays.find(({ date }) => currentDate === date);
          const overtimeDate = {
            countAsOvertime: this.dateRange > 0 ? (holiday ? holiday.countAsOvertime : false) : this.countAsOvertime,
            date: currentDate,
            breakDay: this.shiftType === this.$enums.SHIFT_TYPES.BREAK || this.selectedBreakdays.includes(currentDay),
            shifts: [...this.selectedOffdays, ...this.selectedBreakdays].includes(currentDay)
              ? []
              : this.$helpers.cloneDeep(this.selectedShifts)
          };
          overtimeDate.shifts.forEach(shift => {
            if (this.shiftType === this.$enums.SHIFT_TYPES.OFF_DAY) {
              shift.color = "#EF6C00";
              shift.shiftType = this.$enums.SHIFT_TYPES.OVERTIME;
            } else if (this.shiftType === this.$enums.SHIFT_TYPES.BREAK) {
              shift.color = "#546E7A";
              shift.shiftType = this.$enums.SHIFT_TYPES.OVERTIME;
            }
          });
          payload.overtimeDates.push(overtimeDate);
        }
        this.$api.workplanService
          .multiple(payload)
          .then(response => {
            if (!response.data.error) {
              this.$eventBus.$emit("save:workplan");
              this.innerValue = false;
              this.resetState();
            }
          })
          .catch(console.error)
          .finally(() => (this.loading = false));
      },
      resetState() {
        this.countAsOvertime = false;
        this.shiftType = this.$enums.SHIFT_TYPES.FIX;
        this.selectedShifts = [];
        this.selectedOffdays = [];
        this.selectedBreakdays = [];
        this.$refs.observer.reset();
      }
    }
  };
</script>
